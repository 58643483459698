@charset "UTF-8";
@use 'sass:list';

/// Checks for a valid CSS length.
///
/// @argument {string} $value
///
/// @return {boolean}
///
/// @access private

/* stylelint-disable-next-line scss/at-function-pattern */
@function _is-length($value) {
  @return type-of($value) != 'null' and
    (
      str-slice($value + '', 1, 4) == 'calc' or str-slice($value + '', 1, 3) == 'var' or
        str-slice($value + '', 1, 3) == 'env' or index(auto inherit initial 0, $value) or
        (type-of($value) == 'number' and not(unitless($value)))
    );
}

/// Transforms shorthand that can range from 1-to-4 values to be 4 values.
///
/// @argument {list} $shorthand
///
/// @example scss
///   .element {
///     margin: _unpack-shorthand(1em 2em);
///   }
///
///   // CSS Output
///   .element {
///     margin: 1em 2em 1em 2em;
///   }
///
/// @access private

/* stylelint-disable-next-line scss/at-function-pattern */
@function _unpack-shorthand($shorthand) {
  @if length($shorthand) == 1 {
    @return nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1);
  } @else if length($shorthand) == 2 {
    @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 1) nth($shorthand, 2);
  } @else if length($shorthand) == 3 {
    @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 3) nth($shorthand, 2);
  } @else {
    @return $shorthand;
  }
}

/// Provides a concise, one-line method for setting an element’s positioning
/// properties: `position`, `top`, `right`, `bottom` and `left`. Use a `null`
/// value to “skip” an edge of the box.
///
/// @argument {string} $position
///   A CSS position value.
///
/// @argument {list} $box-edge-values
///   List of lengths; accepts CSS shorthand.
///
/// @example scss
///   .element {
///     @include position(relative, 0 null null 10em);
///   }
///
///   // CSS Output
///   .element {
///     left: 10em;
///     position: relative;
///     top: 0;
///   }
///
/// @example scss
///   .element {
///     @include position(absolute, 0);
///   }
///
///   // CSS Output
///   .element {
///     position: absolute;
///     top: 0;
///     right: 0;
///     bottom: 0;
///     left: 0;
///   }
///
/// @require {function} _is-length
///
/// @require {function} _unpack-shorthand

@mixin position($position, $box-edge-values) {
  $box-edge-values: _unpack-shorthand($box-edge-values);
  $offsets: (
    'top': list.nth($box-edge-values, 1),
    'right': list.nth($box-edge-values, 2),
    'bottom': list.nth($box-edge-values, 3),
    'left': list.nth($box-edge-values, 4),
  );

  position: $position;

  @each $offset, $value in $offsets {
    @if _is-length($value) {
      #{$offset}: $value;
    }
  }
}
