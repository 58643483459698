@use 'src/src/app/scss/abstracts' as *;

@mixin size-2xs($this) {
  font-size: $font-size-l;
  line-height: $font-line-m;
  border-radius: $border-radius-2xs;
  height: 42px;
  font-weight: $font-weight-l;
  padding-left: 14px;
  padding-right: 14px;

  &#{$this}--is-squared {
    width: 38px;
    height: 38px;
  }
}

@mixin size-xs($this) {
  font-size: $font-size-xl;
  line-height: $font-line-l;
  border-radius: $border-radius-2xs;
  height: 46px;
  font-weight: $font-weight-l;

  &#{$this}--is-squared {
    width: 46px;
    height: 46px;
  }
}

@mixin size-s($this) {
  height: 47px;
  padding: 4px 16px 5px;
  font-size: $font-size-3xl;
  border-radius: $border-radius-2xs;

  &#{$this}--is-squared {
    width: 47px;
    height: 47px;
  }
}

@mixin size-m($this) {
  height: 59px;
  font-size: $font-size-3xl;
  padding: 4px 24px 5px;
  border-radius: $border-radius-xs;
  font-weight: $font-weight-l;

  &#{$this}--is-squared {
    width: 59px;
    height: 59px;
  }
}

@mixin size-l($this) {
  height: 71px;
  padding: 4px 40px 5px;
  font-size: $font-size-3xl;

  &#{$this}--is-squared {
    width: 71px;
    height: 71px;
  }
}

@mixin size-xl($this) {
  height: 84px;
  padding: 4px 30px 5px;
  font-size: $font-size-4xl;
  border-radius: $border-radius-s;
  font-weight: $font-weight-xl;

  &#{$this}--is-squared {
    width: 84px;
    height: 84px;
  }
}

.button {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  @include reset-btn;
  @include size-m($block-name);

  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 0;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
  transition: all $transition-fast;
  font-family: $font-family-main;
  font-style: normal;
  line-height: $font-line-l;
  text-align: center;
  background-color: $color-semantic-main;
  color: $color-semantic-third;
  letter-spacing: -1.08px;

  > span {
    @include ellipsis(100%);

    position: relative;
    z-index: 1;
  }

  > .icon {
    position: relative;
    z-index: 1;
  }

  &:hover {
    background: $color-base-purple-900;
    color: $color-semantic-third;
  }

  &:active {
    color: rgba($color-semantic-third, 0.9);
    background-color: $color-semantic-main;
  }

  &__loader {
    display: inline-block;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 0;
    line-height: 0;
  }

  &--size-2xs {
    @include size-2xs($block-name);
  }

  &--size-xs {
    @include size-xs($block-name);
  }

  &--size-s {
    @include size-s($block-name);
  }

  &--size-m {
    @include size-m($block-name);

    @include mq($until: md) {
      @include size-xs($block-name);
    }
  }

  &--size-l {
    @include size-l($block-name);
  }

  &--size-xl {
    @include size-xl($block-name);

    @include mq($until: lg) {
      @include size-m($block-name);
    }

    @include mq($until: md) {
      @include size-xs($block-name);
    }
  }

  &--variant-primary {
    background: $color-base-purple-500;

    &:hover {
      background: darken($color: $color-base-purple-500, $amount: 5%);
    }

    &:active {
      background: darken($color: $color-base-purple-500, $amount: 10%);
    }
  }

  &--variant-secondary {
  }

  &--variant-gradient {
    &::after,
    &::before {
      @include position(absolute, -1px);

      content: '';
      display: block;
      transition: all $transition-fast;
    }

    &::before {
      opacity: 0;
      background: $color-gradient-purple-horizontal-default;
      transform: translate3d(0, 0, 0) rotate(-180deg);
    }

    &::after {
      opacity: 1;
      background: $color-gradient-purple-horizontal-default;
      transform: translate3d(0, 0, 0) rotate(0);
    }

    &:hover {
      &::before {
        opacity: 1;
      }

      &::after {
        opacity: 0;
      }
    }

    &:active {
      &::before,
      &::after {
        opacity: 0;
      }
    }
  }

  &--variant-third {
    background: $color-base-purple-900;

    &:hover {
      background: $color-base-purple-500;
    }

    &:active {
      background: darken($color: $color-base-purple-500, $amount: 5%);
    }
  }

  &--variant-fourth {
    background-color: transparent;
  }

  &--is-rounded-corners {
    border-radius: 150px;
  }

  &--is-squared {
    align-items: center;
    justify-content: center;
    padding: 5px;

    > span {
      display: none;
    }

    &#{$block-name} {
      &--size-s {
        @include mq($until: lg) {
          width: 37px;
          height: 37px;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      &--is-rounded-corners {
        border-radius: 50%;
      }
    }
  }

  &--disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
  }

  &--loading {
    pointer-events: none !important;

    > *:not(.button__loader) {
      opacity: 0 !important;
    }
  }
}
